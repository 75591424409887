<template>
  <ValidationObserver
    class="bg-white modal-card rounded"
    style="width: 50vw"
    ref="observer"
  >
    <div class="bg-primary-100 p-4 text-xl">
      {{ editGroup ? 'Modification' : 'Création' }} d'un groupe de sondages
    </div>
    <div
      class="p-5 overflow-y-auto"
      style="max-height: 80vh"
    >
      <t-input-group
        label="Titre du sondage à questions multiples"
        class="mb-7"
      >
        <t-input v-model="grouptitle" />
      </t-input-group>
      <div
        v-for="(survey, index) in surveys"
        :key="index"
      >
        <div class="grid grid-cols-4 gap-3">
          <div class="col-span-4">
            <ValidationProvider
              name="Question"
              rules="required|min:10|max:180"
              v-slot="{ errors }"
            >
              <t-input-group
                :label="`Question ${index + 1}`"
                :variant="{ 'danger': errors[0] }"
                :feedback="errors[0]"
              >
                <t-input
                  v-model="survey.question"
                  :variant="{ 'danger': errors[0] }"
                />
              </t-input-group>
            </ValidationProvider>
          </div>
          <div class="col-span-4 flex flex-col gap-5">
            <div
              v-for="(option, i) in survey.options"
              :key="i"
            >
              <ValidationProvider
                class="flex justify-between"
                :name="`Réponse ${i + 1}`"
                rules="required|min:3|max:180"
                v-slot="{ errors }"
              >
                <t-input-group
                  class="w-full"
                  :label="`Réponse ${i + 1}`"
                  :variant="{ 'danger': errors[0] }"
                  :feedback="errors[0]"
                >
                  <t-input
                    v-model="survey.options[i].option"
                    placeholder="Choisir une réponse..."
                    :variant="{ 'danger': errors[0] }"
                    :disabled="survey.options[i].option === 'Ne souhaite pas répondre'"
                  />
                </t-input-group>
                <div
                  v-if="i > 1"
                  class="col-span-1 p-3 flex items-end justify-center"
                  @click="removeOption(index, i)"
                >
                  <i class="fas fa-times text-red-700" />
                </div>
              </ValidationProvider>
            </div>
            <div class="flex mx-8 space-x-4">
              <div
                v-if="index > 1"
                class="w-1/2"
              >
                <t-button
                  @click="removeSurvey(index)"
                  class="w-full"
                  variant="error"
                >Supprimer la question</t-button>
              </div>
              <div
                v-else
                class="w-1/2"
              ></div>
              <div class="w-1/2">
                <t-button
                  @click="addOption(index)"
                  class="w-full"
                >+ Ajouter une option</t-button>
              </div>
              <div class="w-1/2">
                <t-button
                  @click="addNoReplyOption(index)"
                  class="w-full"
                  :disabled="survey.noReplyAdded"
                >+ Ajouter option 'ne souhaite pas répondre'</t-button>
              </div>
            </div>
            <div class="flex space-x-6">
              <t-input-group label="Plusieurs réponses possibles">
                <t-toggle v-model="survey.multiple_answers" />
              </t-input-group>
              <t-input-group label="Possibilité de changer de réponse">
                <t-toggle v-model="survey.isReanswerable" />
              </t-input-group>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <t-button
        @click="addSurvey"
        class="w-full mb-12"
      >+ Ajouter une question</t-button>
      <hr />
      <hr />
      <t-input-group
        label="Groupes (facultatif)"
        v-if="usersGroups && usersGroups.length"
        class="mt-4"
      >
        <t-rich-select
          multiple
          :close-on-select="false"
          :options="usersGroups"
          placeholder="Sélectionner des groupes"
          v-model="groupsSelected"
        ></t-rich-select>
        <!-- <span v-if="groupsSelected.length > 0">
          {{ groupsSelected.length }} groupes sélectionnés
        </span> -->
      </t-input-group>
      <div class="flex space-x-4">
        <ValidationProvider
          class="w-1/2"
          name="Début du sondage"
          rules="required"
          v-slot="{ errors }"
          v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
        >
          <t-input-group
            label="Début du sondage"
            :variant="{ 'danger': errors[0] }"
            :feedback="errors[0]"
            class="my-3"
          >
            <t-datepicker
              timepicker
              required
              class=""
              v-model="groupStartAt"
              :maxDate="groupEndAt"
              placeholder="Clique pour sélectionner la date de début du sondage..."
              dateFormat="Y-m-d H:i:S"
              userFormat="d/m/Y H:i"
            />
          </t-input-group>
        </ValidationProvider>
        <ValidationProvider
          class="w-1/2"
          name="Fin du sondage"
          rules="required"
          v-slot="{ errors }"
          v-if="domainUuid !== '2bc5decc-cd2e-46ad-92d7-f17234fdeebd'"
        >
          <t-input-group
            label="Fin du sondage"
            :variant="{ 'danger': errors[0] }"
            :feedback="errors[0]"
            class="my-3"
          >
            <t-datepicker
              timepicker
              required
              v-model="groupEndAt"
              :minDate="groupStartAt"
              placeholder="Clique pour sélectionner la date de fin du sondage..."
              dateFormat="Y-m-d H:i:S"
              userFormat="d/m/Y H:i"
            />
          </t-input-group>
        </ValidationProvider>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 flex justify-between ">
      <div>
        <t-input-group
          label="Actif"
          class="flex space-x-4"
        >
          <t-toggle v-model="groupIsEnabled" />
        </t-input-group>
      </div>
      <div class="flex space-x-2">
        <t-button
          class="m-0"
          @click="notify"
          v-if="editGroup"
        ><i class="fa-regular fa-bell"></i> Renotifier</t-button>
        <t-button
          v-if="editGroup"
          @click.prevent="deleteGroup"
          variant="error"
        >Supprimer le groupe</t-button>
        <t-button
          @click.prevent="createGroup"
          variant="success"
        >Sauvegarder le groupe</t-button>
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'app-survey-group-create',
  props: {
    domainUuid: String,
    editGroup: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain'
    ])
  },
  data () {
    return {
      grouptitle: '',
      surveys: [
        this.createNewSurvey(),
        this.createNewSurvey()
      ],
      groupStartAt: null,
      groupEndAt: null,
      groupIsEnabled: false,
      usersGroups: [],
      groupsSelected: []
    }
  },
  async beforeMount () {
    await this.fetchGroups()
  },
  created () {
    if (this.editGroup) {
      this.populateFormForEdit()
    }
  },
  methods: {
    async fetchGroups () {
      try {
        const response = await http.get('/groups')
        this.usersGroups = response.data.map(group => ({
          value: group.uuid,
          text: group.label
        }))
      } catch (error) {
        console.error('Error fetching groups:', error)
      }
    },
    populateFormForEdit () {
      this.grouptitle = this.editGroup.group_title
      this.groupStartAt = this.editGroup.start_at
      this.groupEndAt = this.editGroup.end_at
      this.groupIsEnabled = Boolean(this.editGroup.is_enabled)
      this.groupsSelected = this.editGroup.groups
      this.surveys = this.editGroup.surveys.map(survey => ({
        uuid: survey.uuid,
        question: survey.question,
        multiple_answers: survey.multiple_answers,
        isReanswerable: survey.is_reanswerable === 1,
        options: survey.options.map(option => {
          return option.uuid ? {
            uuid: option.uuid,
            option: option.option
          } : option.option
        }),
        noReplyAdded: survey.options.some(option => option.option === 'Ne souhaite pas répondre')
      }))
    },
    addSurvey () {
      this.surveys.push(this.createNewSurvey())
    },
    removeSurvey (index) {
      this.surveys.splice(index, 1)
    },
    removeOption (surveyIndex, optionIndex) {
      if (this.surveys[surveyIndex].options[optionIndex].option === 'Ne souhaite pas répondre') {
        this.surveys[surveyIndex].noReplyAdded = false
      }
      this.surveys[surveyIndex].options.splice(optionIndex, 1)
    },
    createNewSurvey () {
      return {
        question: '',
        options: [{ option: '' }, { option: '' }],
        multiple_answers: false,
        noReplyAdded: false,
        isReanswerable: false,
        groups: []
      }
    },
    addOption (surveyIndex) {
      let survey = this.surveys[surveyIndex]
      if (survey.options.some(option => typeof option === 'string' ? option.trim() === '' : option.option.trim() === '')) return
      const noReplyIndex = survey.options.findIndex(option => option.option === 'Ne souhaite pas répondre')
      if (noReplyIndex > -1) {
        survey.options.splice(noReplyIndex, 0, { option: '' })
      } else {
        survey.options.push({ option: '' })
      }
    },
    addNoReplyOption (surveyIndex) {
      this.removeNoReplyOption(surveyIndex)
      this.surveys[surveyIndex].options.push({ option: 'Ne souhaite pas répondre' })
      this.surveys[surveyIndex].noReplyAdded = true
    },
    removeNoReplyOption (surveyIndex) {
      const index = this.surveys[surveyIndex].options.findIndex(option => option.option === 'Ne souhaite pas répondre')
      if (index > -1) {
        this.surveys[surveyIndex].options.splice(index, 1)
      }
      this.surveys[surveyIndex].noReplyAdded = false
    },
    async createGroup () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$buefy.dialog.alert({
          title: 'Erreurs dans le formulaire',
          message: 'Veuillez remplir correctement les champs du formulaire avant de continuer.'
        })
        return
      }
      let groupData = {
        uuid: this.editGroup ? this.editGroup.uuid : null,
        title: this.grouptitle,
        start_at: this.groupStartAt,
        end_at: this.groupEndAt,
        is_enabled: this.groupIsEnabled,
        domain: this.domain.uuid,
        groupUuids: this.groupsSelected,
        surveys: this.surveys.map(survey => ({
          uuid: survey.uuid,
          question: survey.question,
          options: survey.options.map(option => {
            if (this.editGroup && option.uuid) {
              return {
                uuid: option.uuid,
                option: option.option
              }
            } else {
              return typeof option === 'string' ? option : option.option
            }
          }),
          multiple_answers: survey.multiple_answers,
          start_at: this.groupStartAt,
          end_at: this.groupEndAt,
          is_enabled: this.groupIsEnabled,
          is_reanswerable: survey.isReanswerable
        }))
      }
      if (this.editGroup) {
        const updateUrl = `/surveys/updategroup/${this.editGroup.uuid}`
        http.put(updateUrl, groupData)
          .then(({ data }) => {
            this.$emit('updated', data)
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3500,
              message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce groupe de sondages',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          }).finally(() => {
            this.$parent.close()
          })
      } else {
        const createUrl = '/surveys/creategroup'
        http.post(createUrl, groupData).then(({ data }) => {
          this.$emit('created', data)
        }).catch(err => {
          console.log(err)
          this.$buefy.notification.open({
            duration: 3500,
            message: 'Nos serveurs rencontrent des difficultés à créer ce groupe de sondages',
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        }).finally(() => {
          this.$parent.close()
        })
      }
    },
    deleteGroup () {
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Êtes vous sur de vouloir supprimer ce groupe de sondages ?`,
        cancelText: 'Annuler',
        confirmText: 'Confirmer',
        type: 'is-danger',
        onConfirm: () => {
          http.delete(`/surveys/deletegroup/${this.editGroup.uuid}`).then(() => {
            this.$emit('deleted')
          }).catch(err => {
            console.log(err)
            this.$buefy.notification.open({
              duration: 3500,
              message: 'Nos serveurs rencontrent des difficultés à supprimer ce groupe de sondages',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          }).finally(() => {
            this.$parent.close()
          })
        }
      })
    },
    notify () {
      http.post(`/surveys/${this.editGroup.uuid}/notify-group`).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le sondage multiple va être notifié !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$parent.close()
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce groupe de sondage',
          position: 'is-bottom-left',
          type: 'is-warning',
          icon: 'server'
        })
      })
    }
  }
}
</script>
